import { BaseSyntheticEvent } from 'react';

import { GetUrlType } from './public-app-links';

type ProfileAnchors = 'about' | 'contact' | 'portfolio' | 'reviews' | 'services';

type GoToProfileProps = {
  event: BaseSyntheticEvent;
  getUrl: GetUrlType;
  slug: string;
  anchor?: ProfileAnchors;
};

export const goToProfile = (props: GoToProfileProps) => {
  const { event, getUrl, slug, anchor } = props;
  event.preventDefault();
  event.stopPropagation();

  window.open(getUrl('agency.profile', { slug, anchor }), '_blank');
};
