import { CheckRounded, ContentCopyTwoTone } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useState } from 'react';

import { trackShareClicked } from '_components/Comparator/Menu/trackers';
import { ComparedAgency } from '_components/Comparator/utils';
import { usePublicAppContext } from '_core/context/public-app-context';

type Props = {
  agencies: ComparedAgency[];
};

export const ShareLink = (props: Props) => {
  const { agencies } = props;
  const { t } = useTranslation(['common']);
  const { domainInfo } = usePublicAppContext();
  const pathUrl = domainInfo?.getOriginUrl() ?? process.env.NEXT_PUBLIC_QUERY_API_BASE_URL;
  const slugs = agencies.map(({ slug }) => slug);
  const buf = Buffer.from(JSON.stringify(slugs));
  const encodedSlugs = buf.toString('base64');
  const link = `${pathUrl}/share/${encodedSlugs}`;

  const [copied, setCopied] = useState<boolean>(false);

  const handleClick = () => {
    trackShareClicked({ slugs });
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Button
      size="xs"
      buttonStyle={copied ? 'success' : 'secondary'}
      buttonVariant="light"
      label={copied ? t('common:comparator.share.copied') : t('common:comparator.share.copyLink')}
      id={copied ? 'copied-btn' : 'copy-link-btn'}
      iconRight={copied ? <CheckRounded /> : <ContentCopyTwoTone />}
      onClick={handleClick}
    />
  );
};
