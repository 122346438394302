import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { PersonaSelector } from '_components/form/PersonaSelector';
import { getPersonaOptions } from '_components/form/PersonaSelector/utils';
import { RadioPicker } from '_components/form/RadioPicker';
import type { FreeTextOption, NestedError } from '_types/generic';

import { ErrorLabel } from '../../components/ErrorLabel';
import { QuestionStructure } from '../../components/QuestionStructure';
import { BriefingQuestionProps } from '../types';
import { defaultStyles } from '../utils';
import { getPersonaLabel } from './utils';

export const PersonaQuestion = (props: BriefingQuestionProps) => {
  const { t } = useTranslation(['common', 'briefing']);
  const {
    bodyClass = defaultStyles['bodyClass'],
    className,
    editing = true,
    edit,
    cancelEdit,
    name = 'persona',
    title = t('briefing:persona.question'),
    titleClass = defaultStyles['titleClass'],
    type = 'select',
    topSnackBar,
    isMandatory,
  } = props;
  const { control, trigger } = useFormContext();
  const options = getPersonaOptions(t);

  return (
    <div className={className} data-testid={`${name}-question-answer`}>
      {topSnackBar ? topSnackBar : null}

      <div className={bodyClass}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <QuestionStructure
                name={name}
                title={title}
                titleClass={titleClass}
                isMandatory={isMandatory}
                children={
                  <Fragment>
                    {type === 'select' && (
                      <PersonaSelector
                        minHeight="44px"
                        handleChange={(persona: FreeTextOption) => {
                          onChange(persona);
                          trigger(name);
                        }}
                        currentPersona={value}
                      />
                    )}
                    {type === 'radio' && (
                      <RadioPicker
                        name={name}
                        onPick={(persona: FreeTextOption) => {
                          onChange(persona);
                          trigger(name);
                        }}
                        picked={value}
                        choices={options}
                        otherType="inputText"
                      />
                    )}
                    {!topSnackBar && (
                      <ErrorLabel message={(error as unknown as NestedError<FreeTextOption>)?.tag?.message} />
                    )}
                  </Fragment>
                }
                answer={getPersonaLabel(t, value)}
                editing={editing}
                edit={edit}
                cancelEdit={cancelEdit}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
