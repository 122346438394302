import { AddRounded } from '@mui/icons-material';
import { Tooltip } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useRouter } from 'next/router';

import { initCompare } from '_components/Comparator/utils';
import { trackAgenciesSearched } from '_components/SearchTopic/trackers';
import { usePublicAppContext } from '_core/context/public-app-context';

import * as S from './styles';

const EMPTY_SLOTS = 4;

type Props = {
  onClose: () => void;
};

export const EmptySlots = (props: Props) => {
  const { onClose } = props;
  const router = useRouter();
  const { t } = useTranslation(['common']);
  const { domainInfo, locale } = usePublicAppContext();

  const goToSearch = () => {
    initCompare();
    window.location.href = `${domainInfo?.getLocalizedUrl(locale)}/search`;
  };

  const handleClick = () => {
    trackAgenciesSearched({ page: 'comparator-briefing', element: 'list-placeholder' });
    router.asPath?.replace(/\?(.*)/, '') === '/search' ? onClose() : goToSearch();
  };

  return (
    <div className="layout-row">
      {[...Array(EMPTY_SLOTS).keys()].map((index) => {
        return (
          <div key={index}>
            <Tooltip placement="top" title={t('common:comparator.addAnother')} arrow={true}>
              <S.EmptySlot
                onClick={handleClick}
                className="layout-column layout-align-center-center rounded-md m-4 cursor-pointer">
                <AddRounded fontSize="inherit" />
              </S.EmptySlot>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
