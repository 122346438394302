import { ArrowForwardRounded } from '@mui/icons-material';
import { Button, Tooltip } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyArray } from '@sortlist-frontend/utils';

import { ComparedAgency } from '_components/Comparator/utils';

import { EmptySlots } from './EmptySlots';
import { FilledSlots } from './FilledSlots';

type Props = {
  agencies: ComparedAgency[];
  handleUncompare: (agencies: ComparedAgency[]) => void;
  onCompareBriefingStart: () => void;
  onCloseSidebar: (opened: boolean) => void;
};

const EMPTY_SLOTS_NUMBER = 5;

export const List = (props: Props) => {
  const { agencies = [], handleUncompare, onCompareBriefingStart, onCloseSidebar } = props;
  const { t } = useTranslation(['common']);
  const hasFilledSlots = isNonEmptyArray(agencies);
  const hasEmptySlots = agencies.length < EMPTY_SLOTS_NUMBER;
  const emptySlots = hasEmptySlots ? [...Array(EMPTY_SLOTS_NUMBER - agencies.length).keys()] : [];
  const isEmpty = agencies.length == 0;

  return (
    <>
      <div className="flex pb-32 layout-column overflow-y-auto px-32 pt-32">
        <span className="h4 bold mb-16">{t('common:comparator.myShortlist')}</span>
        <p className={'mb-24'}>{t('common:comparator.addFiveAndContact')}</p>

        <div className="bg-secondary-100 border border-secondary-300 p-12 rounded-lg">
          {hasFilledSlots && <FilledSlots agencies={agencies} handleUncompare={handleUncompare} />}
          {hasEmptySlots && <EmptySlots listEmpty={isEmpty} slots={emptySlots} onCloseSidebar={onCloseSidebar} />}
        </div>
      </div>

      <div className="shadow-2-secondary-500 layout-row layout-align-end-center py-16 px-32">
        {hasFilledSlots ? (
          <Button
            size="md"
            className="flex-xs"
            buttonStyle="primary"
            buttonVariant="raised"
            truncate={false}
            label={
              agencies.length > 1
                ? t('common:comparator.contactTheseAgencies')
                : t('common:comparator.contactThisAgency')
            }
            iconRight={<ArrowForwardRounded />}
            onClick={() => onCompareBriefingStart()}
            id="contact-agency-btn"
          />
        ) : (
          <Tooltip placement="top" arrow={true} title={t('common:comparator.atLeastOneAgency')}>
            <div className="layout-row flex-xs">
              <Button
                size="md"
                className="flex-xs"
                buttonStyle="primary"
                buttonVariant="raised"
                truncate={false}
                label={t('common:comparator.contactTheseAgencies')}
                iconRight={<ArrowForwardRounded />}
                disabled={true}
                id="contact-agencies-btn"
              />
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};
