import { useTracker } from '_core/hooks/use-tracker';

type Properties = {
  page?: string;
  projectId?: number;
  agencySlug?: string;
  type?: string;
};

export const trackAgencyAdded = (props: Properties) => {
  const { page, projectId, agencySlug, type } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('agencyAdded', {
    page,
    projectId,
    agencySlug,
    type,
  });
};

export const trackAgencyRemoved = (props: Properties) => {
  const { page, agencySlug } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('agencyRemoved', {
    agencySlug,
    page,
  });
};
