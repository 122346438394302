import { CompareBriefingForm } from '_components/Briefing/versions/CompareBriefing/types';
import { ComparedAgency } from '_components/Comparator/utils';

const getLast = (array?: any[]) => array?.at(array?.length - 1);

// Transform the filters data from localStorage into props usable to prefill the briefing.
export const getBriefingParams = (comparedAgencies: ComparedAgency[]): CompareBriefingForm => {
  // We collect all the slugs, skills and languages.
  const skills: string[] = [];
  const languages: string[] = [];
  const agencySlugs: string[] = [];
  comparedAgencies.map((comparedAgency) => {
    const { slug, searchContext: context } = comparedAgency;
    context?.query?.map((term) => !skills.includes(term.name) && skills.push(term.name));
    context?.languages?.map((lang) => !languages.includes(lang) && languages.push(lang));
    if (slug) agencySlugs.push(slug);
  });

  // We collect the last location, sector, size, expertise, budget of the last agency added.
  const { searchContext } = getLast(comparedAgencies) ?? {};
  const { expertiseIds, budget, location, sectorIds } = searchContext ?? {};

  return {
    agencySlugs,
    skills,
    languages,
    budget,
    ...(location?.placeId && location?.address && { location }),
    expertise: getLast(expertiseIds),
    industry: getLast(sectorIds),
  };
};
