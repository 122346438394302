import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

import { NAVBAR_HEIGHT, NAVBAR_XS_HEIGHT } from '_components/layout/constants';

export const TopBar = styled.div`
  min-height: ${NAVBAR_XS_HEIGHT};

  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    border-bottom: 1px solid ${COLORS.SECONDARY_300};
    min-height: ${NAVBAR_HEIGHT};
  }
`;
