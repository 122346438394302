import { TFunction } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyPlainObject } from '@sortlist-frontend/utils';
import { v4 as uuidv4 } from 'uuid';

import { getPersonaLabel } from '_components/Briefing/questions/Persona/utils';
import { getStoreData } from '_components/Briefing/utils';
import { PublicAppContextState } from '_core/context/public-app-context';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';

import { ExistingProject } from '../MessageBriefing/types';
import {
  CompareBriefingContext,
  CompareBriefingForm,
  CompareBriefingInput,
  CompareBriefingProps,
  CompareBriefingVariables,
} from './types';

export const CURRENT_COMPARE_SCHEMA = 'urn:sortlist:project:briefing:vCompare1.0';

// Centralize contextual information that will be posted with the briefing.
export const getBriefingContext = (
  appContext: PublicAppContextState,
  existingProject?: ExistingProject,
): CompareBriefingContext => {
  const { locale: appLocale, domainInfo } = appContext;
  const { projectUuid = uuidv4() } = existingProject || {};
  const locale = appLocale || 'en';
  const currency = domainInfo?.getCurrency() || 'EUR';
  const iso31661 = domainInfo?.getIso31661() || undefined;
  const sources = getSourceObject();

  return { locale, currency, iso31661, sources, projectUuid };
};

// Prefill the form with defaults and external values coming from the props.
export const getDefaultValues = (props: CompareBriefingProps, locale: string): CompareBriefingForm => {
  const { expertise, skills, location, agencySlugs, languages = [], budget, industry } = props;
  const allLanguages = [...languages, locale];
  const uniqueLanguages = [...new Set(allLanguages)];

  return {
    agencySlugs,
    persona: undefined,
    size: 'all',
    languages: uniqueLanguages,
    expertise,
    skills: skills || [],
    location,
    budget,
    industry,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    terms: false,
  };
};

const getMemberArgs = (props: CompareBriefingInput, t: TFunction) => {
  const { firstName, lastName, email, terms, phone, locale, persona } = props;
  const jobTitle = getPersonaLabel(t, persona);

  return {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(locale && { locale }),
    ...(email && { email }),
    ...(terms && { terms }),
    ...(phone && { phone }),
    ...(persona && { jobTitle }),
  };
};

// Single place where we convert the form values into the variables requested by GraphQL.
// We must keep this unique and isolated so we always know where to correct if one of them change.
export const getVariables = (props: CompareBriefingInput, t: TFunction): CompareBriefingVariables => {
  const {
    agencySlugs,
    budget,
    company,
    currency,
    expertise,
    extraComment,
    industry,
    iso31661,
    locale,
    languages,
    persona,
    size,
    skills,
    sources: sourceArgs = getSourceObject(),
    location,
    projectUuid,
  } = props;

  const { country, placeId, types, coordinates, address, polygon } = location || {};
  const countryCode = country || iso31661;
  const { expertise: storeExpertise, placeId: storePlaceId, address: storeAddress } = getStoreData();

  /*

  We don't ask the expertise in the briefing, but we do prefill it with the expertise of the longtail
  if the user is doing the happy flow. Yet there are many other user journeys were we wouldn't prefill it.

  If we don't have an expertise, the matching performance is hit because we perform the ordering part
  on a way bigger sample of agencies. If the user chose 'Anywhere' as location, it's even worst and it could
  eventually generate downtime on Core-API.

  Usually, if there is no expertise, the Core-API uses Andromeda to find the closest expertise to the skills,
  but this endpoint of Andromeda is temporary disabled. Next week, we'll reimplement it. Till then, I harcode
  a default to 58 (Digital Marketing) which is a 'wide/general' expertise. It's not a problem cause the user
  don't really care about the matching algo in this flow, as he already made his own selection. At worst, the
  'suggestions' on the Results page might a bit less relevant in some cases, but it won't impact our numbers.
  In any case, it's still a confidential A/B test so only a tiny part of the traffic (1-5%) will experience
  this, among which an even tinier part will not have an expertise prefilled yet.

  I'm personally handling all these projects and will update the expertise manually myself in the manager app
  for every project.

  */

  return {
    agencySlugs,
    projectUuid,
    memberUuid: uuidv4(),
    projectArgs: { confidential: true, published: true },
    memberArgs: getMemberArgs(props, t),
    messageArgs: { intent: 'other', content: extraComment },
    sourceArgs,
    briefingArgs: {
      agencySize: size,
      locale: locale,
      languages: languages,
      expertise: expertise || storeExpertise || 58,
      ...(persona && { persona }),
      ...(extraComment && { extraComment }),
      ...(budget && { budget }),
      ...(currency && { currency }),
      ...(countryCode && { iso31661: countryCode }),
      ...(company && { company }),
      ...(industry && { industry }),
      ...(skills && skills.length > 0 && { skills }),
      ...((placeId || address) && {
        location: {
          address: address || storePlaceId,
          placeId: placeId || storeAddress,
          ...(countryCode && { country: countryCode }),
          ...(types && { types }),
          ...(coordinates && { coordinates }),
          ...(polygon && { polygon }),
        },
      }),
    },
  };
};
