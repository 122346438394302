import { TFunction } from '@sortlist-frontend/translation/ssr';
import { array, bool, number, object, string } from 'yup';

import { isPhoneNumber } from '_core/utils/utils';

export const getSchema = (t: TFunction) => {
  const pleaseEnterValidPhone = t('common:errors.pleaseEnterValidPhone');
  const enterValidEmail = t('common:errors.enterValidEmail');
  const firstNameMissing = t('common:errors.firstNameMissing');
  const lastNameMissing = t('common:errors.lastNameMissing');
  const emailRequired = t('common:errors.emailRequired');
  const personaMissing = t('common:errors.personaMissing');
  const companyMissing = t('common:errors.companyMissing');
  const extraCommentMissing = t('common:errors.extraCommentMissing');

  return object().shape({
    agencySlugs: array().of(string().required()),
    budget: array(),
    company: object().shape({
      name: string().required(companyMissing),
      domain: string(),
      logo: string(),
    }),
    email: string().email(enterValidEmail).required(emailRequired),
    expertise: number(),
    extraComment: string().required(extraCommentMissing),
    firstName: string().required(firstNameMissing),
    industry: number(),
    languages: array().of(string()),
    lastName: string().required(lastNameMissing),
    location: object().shape({
      address: string(),
      placeId: string(),
    }),
    persona: object().shape({
      tag: string().required(personaMissing),
      value: string(),
    }),
    phone: string().test('checkPhone', pleaseEnterValidPhone, (value) => !value || isPhoneNumber(value as string)),
    size: string(),
    skills: array().of(string().required()),
    terms: bool(),
  });
};
