import { Input } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyString } from '@sortlist-frontend/utils';
import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorLabel } from '../../components/ErrorLabel';
import { QuestionStructure } from '../../components/QuestionStructure';
import { BriefingQuestionProps } from '../types';
import { defaultStyles } from '../utils';

export const FirstNameQuestion = (props: BriefingQuestionProps) => {
  const { control, trigger } = useFormContext();
  const { t } = useTranslation(['common', 'briefing']);
  const {
    className,
    editing = true,
    edit,
    cancelEdit,
    name = 'firstName',
    showErrorOnSubmit = false,
    title = t('briefing:firstName.question'),
    titleClass = defaultStyles['titleClass'],
    bodyClass = defaultStyles['bodyClass'],
    inputHeight = 'sm',
    isMandatory,
  } = props;

  return (
    <div className={className} data-testid={`${name}-question-answer`}>
      <div className={bodyClass}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => {
            const showError = showErrorOnSubmit ? showErrorOnSubmit : isDirty;

            return (
              <QuestionStructure
                name={name}
                title={title}
                titleClass={titleClass}
                children={
                  <Fragment>
                    <Input
                      height={inputHeight}
                      onChange={(ev) => {
                        onChange(ev.target.value);
                        trigger(name);
                      }}
                      value={value}
                      type="text"
                      placeholder={t('common:briefing.firstNamePlaceholder')}
                    />
                    {showError && <ErrorLabel className="mt-8" message={error?.message} />}
                  </Fragment>
                }
                answer={isNonEmptyString(value) ? value : undefined}
                editing={editing}
                edit={edit}
                cancelEdit={cancelEdit}
                isMandatory={isMandatory}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
