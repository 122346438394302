import { ComparedAgency } from '_components/Comparator/utils';

import { AgencyCard } from './Card';

type Props = {
  agencies: ComparedAgency[];
  handleUncompare: (agencies: ComparedAgency[]) => void;
};

export const FilledSlots = (props: Props) => {
  const { agencies, handleUncompare } = props;

  return (
    <div className="layout-column">
      {agencies.map((agency, index) => (
        <AgencyCard key={index} slug={agency.slug} onChange={handleUncompare} />
      ))}
    </div>
  );
};
