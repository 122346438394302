import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const EmptySlot = styled.div`
  height: 74px;
  border: 1px dashed ${COLORS.SECONDARY_300};
  color: ${COLORS.NEUTRAL_300};
  &:hover {
    border: 1px dashed ${COLORS.SECONDARY_500};
    color: ${COLORS.SECONDARY_500};
  }
`;
