import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackRounded, ArrowForwardRounded, Check } from '@mui/icons-material';
import { Avatar, Button, Loader } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { HttpException, isNonEmptyPlainObject } from '@sortlist-frontend/utils';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ErrorLabel } from '_components/Briefing/components/ErrorLabel';
import { TextAreaQuestion } from '_components/Briefing/questions/TextAreaQuestion';
import { trackBriefingCompleted, trackBriefingStarted } from '_components/Briefing/trackers';
import { getBackendErrorMessage } from '_components/Briefing/utils';
import { clearCompare, uncompare } from '_components/Comparator/utils';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTrack } from '_core/repos/track.repo';
import { execWithTimeout } from '_core/utils/utils';
import { CompareBriefingPayload } from '_pages/api/proxy/project/compare-briefing';

import { MessageBriefing } from '../MessageBriefing';
import { getExistingProject, storeExistingPoject } from '../MessageBriefing/utils';
import { useCreateCompareBriefing } from './compare-briefing.repo';
import { ComparatorContactInfoForm } from './ContactInfoForm';
import { EmptySlots } from './EmptySlots';
import { CompareBriefingForm, CompareBriefingProps } from './types';
import { CURRENT_COMPARE_SCHEMA, getBriefingContext, getDefaultValues, getVariables } from './utils';
import { getSchema } from './validation';

export const CompareBriefing = (props: CompareBriefingProps) => {
  const { skills, location, industry, languages, budget, expertise, singleAgency, onStop, onClose } = props;
  const { t } = useTranslation(['common', 'briefing']);
  const validationSchema = getSchema(t);

  const { slug, name, logo } = singleAgency || {};
  // If slug is present, it's a direct contact. From the "contact" button, not from the list.
  const isDirectContact = !!slug;
  const existingProject = isDirectContact ? getExistingProject() : undefined;

  const appContext = usePublicAppContext();
  const context = getBriefingContext(appContext, existingProject);
  const { locale, sources, iso31661, projectUuid } = context;
  const { absoluteEntryUrl, sessionEntryUrl } = sources ?? {};

  const [isPosting, setIsPosting] = useState<boolean>(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState<string | undefined>(undefined);

  const { mutate: createBriefing } = useCreateCompareBriefing();
  const { mutateAsync: internalTrack } = useTrack();

  const { ...methods } = useForm<CompareBriefingForm>({
    defaultValues: getDefaultValues(props, locale),
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
  });

  const {
    watch,
    trigger,
    formState: { errors },
  } = methods;
  const formValues: CompareBriefingForm = watch();

  const onSuccessPost = () => {
    appContext.showSnackbar({ message: t('briefing:messageSent'), icon: <Check className="p text-secondary-100" /> });
    onClose && onClose();
  };

  const onSuccess = (payload?: CompareBriefingPayload) => {
    setBackendErrorMessage(undefined);

    const { extraComment: message, persona, phone } = { ...formValues };
    const { response, token: encodedProjectUuid } = { ...payload };
    const { projectId, uuid: projectUuid } = { ...response?.data?.createProject };
    const memberUuid = response?.data?.createProjectMember?.uuid;

    if (projectId && memberUuid && encodedProjectUuid && projectUuid) {
      isDirectContact ? uncompare({ slug }) : clearCompare();
      storeExistingPoject({ message, memberUuid, projectId, projectUuid, encodedProjectUuid });

      const payload = {
        direct: false,
        comparator: true,
        description: Boolean(message),
        job: `${persona?.tag}`,
        phone: Boolean(phone),
        projectId: projectId,
      };

      execWithTimeout(async (resolve, _reject) => {
        await Promise.all([
          internalTrack({
            name: 'briefingCompleted',
            briefing_type: 'compare',
            url: window.location.href?.replace('/directory-actions', ''),
            project_external_id: projectUuid,
          }).catch(() => {}),
          trackBriefingCompleted(payload),
        ]);
        resolve();
      }).finally(() => {
        onSuccessPost();
      });
    }
  };

  const handleOnError = (error: HttpException) => {
    setBackendErrorMessage(getBackendErrorMessage(error, t));
    setIsPosting(false);
  };

  const handleCreate = () => {
    setIsPosting(true);

    trigger().then((isValid) => {
      if (!isValid) return setIsPosting(false);

      const variables = getVariables({ ...formValues, ...context }, t);
      isNonEmptyPlainObject(variables) &&
        createBriefing(variables, { onSuccess, onError: (e: HttpException) => handleOnError(e) });
    });
  };

  const handleMessageCreated = () => {
    if (existingProject) {
      const { projectId, message, projectUuid } = existingProject;
      const payload = {
        direct: false,
        comparator: true,
        description: Boolean(message),
        projectId: parseInt(`${projectId}`),
      };

      execWithTimeout(async (resolve, _reject) => {
        await Promise.all([
          internalTrack({
            name: 'briefingCompleted',
            briefing_type: 'compare',
            url: window.location.href?.replace('/directory-actions', ''),
            project_external_id: projectUuid,
          }).catch(() => {}),
          trackBriefingCompleted(payload),
        ]);
        resolve();
      }).finally(() => {
        onSuccessPost();
      });
    }
  };

  useEffect(() => {
    internalTrack({
      name: 'briefingStarted',
      briefing_type: 'compare',
      url: window.location.href,
      project_external_id: projectUuid,
      cta: 'ask-for-quote',
    }).catch(() => {});
    trackBriefingStarted({
      page: 'comparator-list',
      cta: 'ask-for-quote',
      schema: CURRENT_COMPARE_SCHEMA,
      direct: false,
      comparator: true,
      skills: Boolean(skills?.length),
      location: Boolean(location),
      industry: Boolean(industry),
      langs: Boolean(languages?.length),
      budget: Boolean(budget),
      expertise,
      absoluteEntryUrl,
      sessionEntryUrl,
    });
  }, []);

  const logoSrc = logo ? ImageSourceHelper.fromUrl(logo, { width: 400 }) : undefined;

  return (
    <div className="layout-column flex">
      {isPosting ? (
        <Loader size="sm" />
      ) : (
        <Fragment>
          {existingProject ? (
            <MessageBriefing
              title={t('common:comparator.briefing.sendMessageToAgency', { agency: name })}
              agencySlug={slug as string}
              existingProject={existingProject}
              callback={handleMessageCreated}
            />
          ) : (
            <FormProvider {...methods}>
              <form className="layout-column flex">
                <div className="layout-column flex overflow-y-auto px-16 px-gt-sm-32 pb-32">
                  <div className="layout-column pt-32">
                    {isDirectContact ? (
                      <Fragment>
                        <p className="mx-8 mb-8">{t('common:comparator.wannaContactMore')}</p>
                        <div className="layout-row layout-align-start-center mx-4 mb-32">
                          <Avatar shape="square" src={logoSrc} fit="contain" size="md" className="m-4" />
                          <EmptySlots onClose={onClose} />
                        </div>

                        <h3 className="bold mx-8">
                          {t('common:comparator.briefing.sendMessageToAgency', { agency: name })}
                        </h3>
                      </Fragment>
                    ) : null}
                  </div>

                  <div className="mx-8">
                    <TextAreaQuestion
                      title={t('common:briefing.whatIsYourMessage')}
                      placeholder={t('common:briefing.typeYourMessage')}
                      bodyClass="mb-24"
                    />
                  </div>

                  <ComparatorContactInfoForm iso31661={iso31661} />

                  {backendErrorMessage && <ErrorLabel message={backendErrorMessage} />}
                </div>

                <div className="layout-row layout-align-space-between-center shadow-2-secondary-700 px-16 px-gt-sm-32 py-16">
                  {!isDirectContact && (
                    <Button
                      type="button"
                      size="md"
                      className="mx-8"
                      buttonStyle="secondary"
                      buttonVariant="outlined"
                      animation="ripple"
                      iconLeft={<ArrowBackRounded />}
                      onClick={onStop}
                      label={t('common:comparator.briefing.previous')}
                      id="comparator-previous-btn"
                    />
                  )}

                  <div className="flex"></div>

                  <Button
                    type="button"
                    size="md"
                    className="mx-8"
                    buttonStyle="primary"
                    buttonVariant="raised"
                    animation="ripple"
                    loading={isPosting}
                    iconRight={<ArrowForwardRounded />}
                    onClick={handleCreate}
                    label={t('common:comparator.briefing.justSend')}
                    id="comparator-send-btn"
                  />
                </div>
              </form>
            </FormProvider>
          )}
        </Fragment>
      )}
    </div>
  );
};
