import { OnReceiveMessage, useWindowMessage, WindowMessageParams } from '@sortlist-frontend/utils';
import { useState } from 'react';

import { useComparedAgencies } from '_core/hooks/use-compared-agencies';

import { ComparedAgency } from '../utils';
import { Sidebar } from './Sidebar';

type Action = 'open-comparator' | 'close-comparator';
type Data = null;

export const useComparatorMessage = (callback?: WindowMessageParams<Action, Data>) =>
  useWindowMessage<Action, Data>(callback);

export const ComparatorSidebarEmbed = () => {
  const [open, setOpen] = useState(false);

  const { comparedAgencies, setComparedAgencies } = useComparedAgencies();
  const handleUncompare = (agencies: ComparedAgency[]) => setComparedAgencies(agencies);

  useComparatorMessage({
    onReceiveMessage: (action) => {
      if (action === 'open-comparator') {
        setOpen(true);
      }
      if (action === 'close-comparator') {
        setOpen(false);
      }
    },
    sendToIframeId: 'directory-actions',
  });

  return (
    <Sidebar opened={open} onClose={setOpen} comparedAgencies={comparedAgencies} handleUncompare={handleUncompare} />
  );
};
