import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { Select } from '_components/styled/shared';
import type { FreeTextOption } from '_types/generic';

import { exportPersona, findOption, getOptions, PersonaOption } from './utils';

type Props = {
  minHeight?: string;
  currentPersona: FreeTextOption;
  handleChange: (persona: FreeTextOption) => void;
};

export const PersonaSelector = (props: Props) => {
  const { minHeight, currentPersona, handleChange } = props;
  const { t } = useTranslation(['common']);
  const options = getOptions(t);

  return (
    <Select
      options={options}
      value={findOption(currentPersona, t)}
      placeholder={t('common:personaSelector.placeholder')}
      isMulti={false}
      isClearable={false}
      minHeight={minHeight}
      onChange={(selected) => handleChange(exportPersona(selected as PersonaOption))}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};
