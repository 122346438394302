import { PhoneInput } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyString, useDebounceCallback } from '@sortlist-frontend/utils';
import { Fragment, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorLabel } from '../../components/ErrorLabel';
import { QuestionStructure } from '../../components/QuestionStructure';
import { BriefingQuestionProps } from '../types';
import { defaultStyles } from '../utils';

export const PhoneQuestion = (props: BriefingQuestionProps) => {
  const { control, trigger, setValue } = useFormContext();
  const { t } = useTranslation(['common', 'briefing']);
  const {
    iso31661,
    className,
    editing = true,
    edit,
    cancelEdit,
    name = 'phone',
    showErrorOnSubmit = false,
    title = t('briefing:phone.question'),
    titleClass = defaultStyles['titleClass'],
    bodyClass = defaultStyles['bodyClass'],
    isMandatory,
  } = props;

  const handleOnChange = (phone: string, iso31661: string, onChange: (...event: any[]) => void) => {
    onChange(phone);
    setValue('phoneIso31661', iso31661);
    trigger(name);
  };

  const debouncedOnChange = useCallback(useDebounceCallback(handleOnChange, 500), []);

  return (
    <div className={className} data-testid={`${name}-question-answer`}>
      <div className={bodyClass}>
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => {
            const showError = showErrorOnSubmit ? showErrorOnSubmit : isDirty;

            return (
              <QuestionStructure
                name={name}
                title={title}
                titleClass={titleClass}
                children={
                  <Fragment>
                    <PhoneInput
                      name={name}
                      defaultValue={value}
                      defaultCountryFlag={iso31661?.toLowerCase()}
                      onChange={(phone: string, iso31661: string) => {
                        debouncedOnChange(phone, iso31661, onChange);
                      }}
                    />
                    {showError && <ErrorLabel className="mt-8" message={error?.message} />}
                  </Fragment>
                }
                answer={isNonEmptyString(value) ? value : undefined}
                editing={editing}
                edit={edit}
                cancelEdit={cancelEdit}
                isMandatory={isMandatory}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
