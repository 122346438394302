import { HttpException } from '@sortlist-frontend/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { isUserError } from '_components/Briefing/utils';
import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';
import { captureGqlError } from '_core/error/capture-gql-error';
import { CompareBriefingPayload } from '_pages/api/proxy/project/compare-briefing';

import { CompareBriefingVariables } from './types';

export const cacheKey = {
  createCompareBriefing: '/api/proxy/project/compare-briefing',
};

const urls = {
  createCompareBriefing: '/api/proxy/project/compare-briefing',
};

export const projectRepo = {
  createCompareBriefing: async (
    variables: CompareBriefingVariables,
    config?: AxiosRequestConfig,
  ): Promise<CompareBriefingPayload> => {
    const response = await api.post(urls.createCompareBriefing, variables, config);
    return response?.data;
  },
};

export const useCreateCompareBriefing = () => {
  const { domainInfo } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';

  return useMutation({
    mutationFn: (variables: CompareBriefingVariables) => {
      return projectRepo.createCompareBriefing(variables, { baseURL });
    },
    onError: (e: HttpException) => {
      if (!isUserError(e)) captureGqlError(e);
    },
  });
};
