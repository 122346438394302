import { gql, useQuery } from '@sortlist-frontend/data-fetching/public';

const AGENCY_QUERY_MIN = gql(`
  query AgencyMin($slug: String!) {
    agency(slug: $slug) {
      name
      logoUrl
      id
    }
  }
`);

export const useFindAgency = ({ slug }: { slug: string }) => {
  const { loading, ...rest } = useQuery(AGENCY_QUERY_MIN, {
    variables: { slug },
  });

  return {
    isLoading: loading,
    ...rest,
  };
};
