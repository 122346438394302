import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { CompanyQuestion } from '_components/Briefing/questions/Company';
import { EmailQuestion } from '_components/Briefing/questions/Email';
import { FirstNameQuestion } from '_components/Briefing/questions/FirstName';
import { LastNameQuestion } from '_components/Briefing/questions/LastName';
import { PersonaQuestion } from '_components/Briefing/questions/Persona';
import { PhoneQuestion } from '_components/Briefing/questions/Phone';

type Props = {
  iso31661?: string;
};

export const ComparatorContactInfoForm = ({ iso31661 }: Props) => {
  const { t } = useTranslation(['briefing', 'common']);

  return (
    <>
      <div className="layout-column layout-gt-xs-row">
        <FirstNameQuestion
          bodyClass="mb-24"
          className="flex mx-8"
          title={t('briefing:firstName.label')}
          showErrorOnSubmit
        />
        <LastNameQuestion
          bodyClass="mb-24"
          className="flex mx-8"
          title={t('briefing:lastName.label')}
          showErrorOnSubmit
        />
      </div>

      <div className="layout-column layout-gt-xs-row">
        <CompanyQuestion
          bodyClass="mb-24"
          className="flex mx-8"
          title={t('briefing:comparator.company')}
          isAutoFocus={false}
          hideWebsite={true}
        />
        <PersonaQuestion bodyClass="mb-24" className="flex mx-8" title={t('briefing:comparator.persona')} />
      </div>

      <div className="layout-column layout-gt-xs-row">
        <EmailQuestion bodyClass="mb-24" className="flex mx-8" title={t('briefing:email.label')} showErrorOnSubmit />
        <PhoneQuestion
          bodyClass="mb-24"
          className="flex mx-8"
          iso31661={iso31661}
          title={t('briefing:comparator.phone')}
          showErrorOnSubmit
        />
      </div>
    </>
  );
};
